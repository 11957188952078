import { Paper, Grid, Typography, Chip, Avatar } from "@mui/material";
import React, { useMemo } from "react";
import theme from "../../../theme";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PersonIcon from "@mui/icons-material/Person";

type Props = {
  population: number;
  growth: string;
};

export const PopulationCard = ({ population, growth }: Props) => {
  const formatter = useMemo(() => new Intl.NumberFormat("en-US"), []);

  return (
    <Paper
      sx={{
        minHeight: 250,
        p: theme.spacing(2),
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "space-between",
      }}
      variant="outlined"
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{ mr: 1, background: theme.palette.secondary.main }}
            variant="rounded"
          >
            <PersonIcon />
          </Avatar>{" "}
          Population
        </Typography>
        <Chip
          clickable
          variant="outlined"
          size="small"
          component="a"
          label="US Census"
          href="https://www.census.gov/en.html"
          target="_blank"
          color="secondary"
        />
      </Grid>
      <Typography variant="h6" width="100%" align="center">
        {formatter.format(population)}
      </Typography>

      <Grid container justifyContent="center">
        <Typography
          variant="overline"
          color={
            Number(growth) > 0
              ? theme.palette.success.main
              : theme.palette.error.main
          }
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          width="100%"
        >
          <TrendingUpIcon sx={{ mr: 1 }} /> {`${growth}% 1-year growth`}
        </Typography>
        <Typography
          variant="overline"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          width="100%"
        >
          Data from US Census - Decennial Census
        </Typography>
      </Grid>
    </Paper>
  );
};
