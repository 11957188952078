import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Paper, Typography } from "@mui/material";
import theme from "../theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options: ChartOptions<"line"> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#FFFFFF",
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      ticks: {
        color: "#FFFFFF",
        stepSize: 0.1,
        callback: function (value, index, values) {
          return "$" + Number(value).toFixed(2);
        },
      },
      grid: {
        display: true,
        drawBorder: false,
        borderDash: [8, 4],
        borderDashOffset: 8,
        color: "rgb(256, 256, 256, 0.5)",
      },
    },
  },
};

export type Props = {
  sqft: number[];
  prices: any[];
};

export function PPSQFTChart(props: Props) {
  const { sqft, prices } = props;

  const nf = new Intl.NumberFormat("en-US");

  const data = {
    labels: sqft.map((s) => nf.format(s) + " sqft"),
    datasets: [
      {
        label: "Price",
        data: prices,
        borderColor: "#FFF",
        backgroundColor: "#FFF",
      },
    ],
  };

  return (
    <Paper
      sx={{
        padding: 2,
        height: "100%",
        background: `linear-gradient(195deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="subtitle2" color="white">
        Price per square foot
      </Typography>
      <Line options={options} data={data} />
    </Paper>
  );
}
