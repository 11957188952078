import { injectAuthHeaders } from "../hooks/auth/auth";
import { baseUrl } from "./config";

export type ZipCodes = { zipCodes: ZipCode[] };

export type ZipCode = {
  displayName: string;
  zipCode: string;
};

export const getZips = async (q: string): Promise<ZipCodes> => {
  const rents = await fetch(`${baseUrl}/search/zips?q=${q}`, {
    headers: injectAuthHeaders({}),
  })
    .then((response) => response.json())
    .then((data: ZipCodes) => {
      console.log(data);
      return data;
    });
  return rents;
};
