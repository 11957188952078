import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ProTip from "./ProTip";
import { Avatar, Grid } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <Box
      sx={{
        padding: 4,
        display: "flex",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Helmet>
        <title>Properana</title>
        <meta
          name="description"
          content={`Properana is the easiest way to find what price your home should rent for.`}
        />
      </Helmet>
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
        maxWidth="sm"
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          color="primary"
        >
          Properana
        </Typography>
        <ProTip />
      </Grid>
    </Box>
  );
}
