import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { Rents } from "./api";
import theme from "./theme";

type RentRow = {
  beds: number;
  baths: string;
  ppsqft: number;
  avgsqft: number;
  low: number;
  mid: number;
  high: number;
};

function createRentData(rent: RentRow) {
  return { ...rent };
}

type Props = {
  rents: Rents[];
  propertyCount: number;
};

export const RentsTable = (props: Props) => {
  const { rents, propertyCount } = props;

  const nf = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  });

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }),
    []
  );

  const rows = useMemo(() => {
    return rents.map(({ rents, filter }) => {
      const row: RentRow = {
        beds: filter.bedsMin,
        baths: `${filter.bathsMin === 0 ? "any" : filter.bathsMin}`,
        ppsqft: rents.ppsqft,
        avgsqft: rents.avgsqft,
        low: rents.low,
        mid: rents.mid,
        high: rents.high,
      };

      return row;
    });
  }, [rents]);

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="simple table">
        <caption>Data from {propertyCount} properties</caption>
        <TableHead sx={{ background: theme.palette.grey[200] }}>
          <TableRow>
            <TableCell>Beds / baths</TableCell>
            <TableCell align="right">
              <Tooltip title="Price per square foot">
                <Typography variant="subtitle2">$ / sqft</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="right">Average sqft</TableCell>
            <TableCell align="right">Low</TableCell>
            <TableCell align="right">Mid</TableCell>
            <TableCell align="right">High</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow hover key={row.beds}>
              <TableCell component="th" scope="row">
                {`${row.beds} beds / ${row.baths} baths`}
              </TableCell>
              <TableCell align="right">${nf.format(row.ppsqft)}</TableCell>
              <TableCell align="right">{nf.format(row.avgsqft)}</TableCell>
              <TableCell align="right">{formatter.format(row.low)}</TableCell>
              <TableCell align="right">{formatter.format(row.mid)}</TableCell>
              <TableCell align="right">{formatter.format(row.high)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
