import * as React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Analysis } from "./pages";
import SignIn from "./pages/signin";
import { Auth } from "./pages/auth";
import { Navbar } from "./components/navbar";
import { AuthProvider } from "./hooks/auth/auth";
import { Box } from "@mui/material";
import { About } from "./pages/about";
import { Pricing } from "./pages/pricing";
import SignUp from "./pages/signup";
import { Footer } from "./components/footer";
import { SnackbarProvider } from "notistack";
import { Privacy } from "./pages/privacy";
import { TOS } from "./pages/tos";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="rent/:zipCode" element={<Analysis />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<TOS />} />

            <Route path="*" element={<App />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AuthProvider>
    </SnackbarProvider>
  </ThemeProvider>,
  document.querySelector("#root")
);
