import { Paper, Grid, Avatar, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { Rents } from "../../../api";
import theme from "../../../theme";
import BedIcon from "@mui/icons-material/Bed";

type Props = {
  beds: number;
  rents: Rents;
};

export const RentCard: React.FC<Props> = ({ beds, rents }) => {
  const numberFormatter = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }),
    []
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }),
    []
  );

  return (
    <Paper
      sx={{ height: 250, display: "flex", alignContent: "center" }}
      variant="outlined"
    >
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        <Grid
          container
          item
          xs={5}
          justifyContent="flex-end"
          alignContent="center"
        >
          <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
            <BedIcon />
          </Avatar>
        </Grid>
        <Grid
          container
          item
          xs={7}
          justifyContent="center"
          alignContent="flex-start"
          flexDirection="column"
        >
          <Typography variant="h6">{beds} beds</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            ${numberFormatter.format(rents.rents.ppsqft)} / sqft
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={4}
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            variant="overline"
            align="center"
            color={theme.palette.error.main}
          >
            Low
          </Typography>
          <Typography color={theme.palette.error.main} align="center">
            {currencyFormatter.format(rents.rents.low)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={4}
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            variant="overline"
            align="center"
            color={theme.palette.secondary.main}
          >
            Mid
          </Typography>
          <Typography color={theme.palette.secondary.main} align="center">
            {currencyFormatter.format(rents.rents.mid)}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={4}
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            variant="overline"
            align="center"
            color={theme.palette.primary.main}
          >
            High
          </Typography>
          <Typography color={theme.palette.primary.main} align="center">
            {currencyFormatter.format(rents.rents.high)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
