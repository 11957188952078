import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { getZips } from "../api";

type MyOption = {
  title: string;
  value: string;
};

type Props = {
  inputValue?: string;
};

export const ZipAutocomplete = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<MyOption[]>([]);
  const [inputValue, setInputValue] = useState<string | undefined>(
    props.inputValue
  );
  const [selectedOption, setSelectedOption] = useState<MyOption | null>(
    props.inputValue
      ? { title: props.inputValue, value: props.inputValue }
      : null
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!inputValue || inputValue.length != 5) {
      return;
    }

    setLoading(true);

    getZips(inputValue)
      .then((data) => {
        setOptions(
          data.zipCodes.map((zipCode) => ({
            title: zipCode.displayName,
            value: zipCode.zipCode,
          }))
        );
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [inputValue]);

  return (
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      options={options}
      noOptionsText="Enter a valid zip code"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.title
      }
      value={selectedOption}
      onChange={(event, newValue) => {
        if (newValue && typeof newValue !== "string") {
          navigate(`/rent/${newValue.value}`);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => (
        <Typography {...props} sx={{ width: "100%", mr: "auto" }}>
          {option.value}
          <Typography
            color="text.secondary"
            variant="caption"
            align="right"
            sx={{ marginLeft: "auto" }}
          >
            {option.title}
          </Typography>
        </Typography>
      )}
      renderInput={(props) => (
        <TextField
          {...props}
          placeholder="Enter zip code"
          InputProps={{
            ...props.InputProps,
            startAdornment: (
              <>
                <SearchIcon />
                {props.InputProps.endAdornment}
              </>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {props.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      onKeyDown={(event) => {
        if (loading) {
          return;
        }

        if (event.key === "Enter") {
          if (options.length > 0 && inputValue?.length === 5) {
            navigate(`/rent/${options[0].value}`);
          }
        }
      }}
      sx={{ width: "100%" }}
    />
  );
};
