import { injectAuthHeaders } from "../hooks/auth/auth";
import { baseUrl } from "./config";

export const verifyToken = async (token: string): Promise<void> => {
  const test = await fetch(`${baseUrl}/auth/verify`, {
    headers: injectAuthHeaders({}),
  });

  if (test.status !== 200) {
    throw new Error("invalid token");
  }
};

type SignInResponse = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  tokenType: string;
};

export const signIn = async (
  username: string,
  password: string
): Promise<SignInResponse> => {
  const res = await fetch(`${baseUrl}/auth/signin`, {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
  });

  if (res.status !== 200) {
    const err = await res.json();
    throw new Error(err.error || "Error signing in.");
  }

  return res.json();
};

export const signUp = async (
  username: string,
  password: string
): Promise<void> => {
  const res = await fetch(`${baseUrl}/auth/signup`, {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
  });

  if (res.status !== 200) {
    const err = await res.json();
    throw new Error(err.error || "Error signing in.");
  }
};
