import { Paper } from "@mui/material";
import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import theme from "../../../theme";

type Props = {
  center: google.maps.LatLngLiteral;
  geometry: object;
};

let map: google.maps.Map;

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

export const MapsCard = ({ center, geometry }: Props) => {
  return (
    <Paper
      variant="outlined"
      sx={{ display: "flex", minHeight: 500, height: "100%" }}
    >
      <Wrapper
        apiKey={"AIzaSyD7L0UH9YYyYpyxyeLD5IkdTknmVx1HWDI"}
        render={render}
      >
        <Map
          center={center}
          style={{ flexGrow: "1", height: "100%" }}
          streetViewControl={false}
          geometry={geometry}
        />
      </Wrapper>
    </Paper>
  );
};

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  geometry: object;
}

const Map: React.FC<MapProps> = ({ center, geometry, children, ...rest }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [zoom, setZoom] = React.useState(11);

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          fullscreenControl: false,
          mapTypeControl: false,
          ...rest,
        })
      );
    }

    if (map) {
      map.data.addGeoJson({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry,
          },
        ],
      });

      map.data.setStyle({
        strokeWeight: 1,
        fillOpacity: 0.1,
        fillColor: theme.palette.secondary.light,
        strokeColor: theme.palette.secondary.dark,
      });
    }
  }, [ref, map, zoom]);

  return (
    <div ref={ref} style={{ flexGrow: "1", height: "100%" }}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </div>
  );
};

const Polygon: React.FC<google.maps.PolygonOptions> = (options) => {
  const [polyline, setPolyline] = React.useState<google.maps.Polygon>();

  React.useEffect(() => {
    if (!polyline) {
      setPolyline(new google.maps.Polygon());
    }

    // remove marker from map on unmount
    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };
  }, [polyline]);

  React.useEffect(() => {
    if (polyline) {
      polyline.setOptions(options);
    }
  }, [polyline, options]);

  return null;
};
