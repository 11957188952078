import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";

export type Feature = {
  icon: React.ReactNode;
  primary: string;
  secondary?: string;
};

type Props = {
  features: Feature[];
};

export const FeatureList = ({ features }: Props) => {
  return (
    <List dense>
      {features.map(({ primary, secondary, icon }) => (
        <ListItem>
          <ListItemAvatar>
            <Avatar>{icon}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
      ))}
    </List>
  );
};
