import { baseUrl } from "./config";

export type Rents = {
  images: { contentUrl: string; width: number; height: number }[];
  filter: {
    displayName: string;
    zip: string;
    bedsMin: number;
    bathsMin: number;
  };
  rents: {
    properties: number;
    displayName: string;
    low: number;
    mid: number;
    high: number;
    ppsqft: number;
    avgsqft: number;
  };
  metadata: {
    population: number;
    growth: string;
    income: number;
    lat: string;
    lng: string;
    geometry: {
      geometry: {
        coordinates: number[][][];
      };
    };
  };
};

export const getRentsForZip = async (
  zip: string,
  bedsMin?: number
): Promise<Rents> => {
  const rents = await fetch(
    `${baseUrl}/rents?zip=${zip}${bedsMin ? `&bedsMin=${bedsMin}` : ``}`
  )
    .then((response) => response.json())
    .then((data: Rents) => data);
  return rents;
};
