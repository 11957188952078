import { createTheme } from "@mui/material/styles";
import { blue, red } from "@mui/material/colors";

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0, 171, 85)",
    },
    secondary: {
      main: blue.A400,
    },
    error: {
      main: red.A400,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default theme;
