import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const idToken = new URLSearchParams(location.hash.substring(1)).get(
    "id_token"
  );

  // TODO validate idToken

  useEffect(() => {
    if (!idToken || idToken.length === 0) {
      return;
    }

    localStorage.setItem("idToken", idToken);
    navigate("/");
  }, [idToken]);

  return <div> authed</div>;
};
