import React, { FormEvent, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../theme";
import { signUp } from "../api/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const redirectUri = process.env.REACT_APP_AUTH_REDIRECT
  ? `${process.env.REACT_APP_AUTH_REDIRECT}/auth`
  : "http://localhost:3000/auth";

export default function SignUp() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const res = await signUp(username, password);

      navigate("/signin");
      enqueueSnackbar("Success! Please sign in with your new account", {
        variant: "success",
      });
    } catch (e: any) {
      enqueueSnackbar(e.toString());
    }
  };

  return (
    <>
      <Helmet>
        <title>Properana - Sign up</title>
        <meta
          name="description"
          content={`Sign up to access all of Properana's features.`}
        />
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Card
          sx={{
            minHeight: 600,
            width: 500,
            display: "flex",
            alignItems: "center",
          }}
          variant="outlined"
        >
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
              >
                <Avatar
                  src="/assets/properana.png"
                  sx={{ width: 128, height: 128 }}
                />
                <Typography variant="h5" color="primary" align="center">
                  Properana
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  Sign up to continue
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  startIcon={
                    <Avatar
                      alt="Google Logo"
                      sx={{ width: 24, height: 24 }}
                      src="assets/g-logo.png"
                    />
                  }
                  variant="outlined"
                  sx={{ color: theme.palette.text.secondary }}
                  href={`https://properana.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=TOKEN&client_id=5f1k1abm4r5pnofenno5osl49n&scope=email openid profile`}
                >
                  Sign up with Google
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 2, mb: 4 }} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              component="form"
              justifyContent="center"
              onSubmit={onSubmit}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={username}
                  placeholder="Email"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid container item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={password}
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Button variant="contained" type="submit" fullWidth>
                  Sign up
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 2 }} />
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Link
                  href="/signin"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/signin");
                  }}
                  underline="none"
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
