import { AppBar, Avatar, Button, Grid, Toolbar } from "@mui/material";
import React from "react";
import { AuthContext } from "../hooks/auth/auth";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import theme from "../theme";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <AuthContext.Consumer>
      {({ isAuthenticated, signOut }) => (
        <AppBar
          elevation={1}
          position="fixed"
          sx={{
            backdropFilter: "blur(6px)",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container maxWidth="md">
            <Grid item xs={12}>
              <Toolbar>
                <Button
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                  startIcon={
                    <Avatar
                      alt="Logo"
                      src="/assets/properana.png"
                      sx={{ height: 48, width: 48 }}
                    />
                  }
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Properana
                </Button>
                <Button
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/");
                  }}
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Home
                </Button>
                <Button
                  href="/about"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/about");
                  }}
                  sx={{ color: theme.palette.text.secondary }}
                >
                  About
                </Button>
                <Button
                  href="/pricing"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/pricing");
                  }}
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Pricing
                </Button>
                {isAuthenticated ? (
                  <Button
                    onClick={signOut}
                    sx={{ ml: "auto" }}
                    variant="outlined"
                  >
                    Sign out
                  </Button>
                ) : (
                  <Button
                    href="/signin"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/signin");
                    }}
                    sx={{ ml: "auto" }}
                    variant="contained"
                  >
                    Sign in
                  </Button>
                )}
              </Toolbar>
            </Grid>
          </Grid>
        </AppBar>
      )}
    </AuthContext.Consumer>
  );
};
