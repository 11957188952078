import { Paper, Grid, Typography, Chip, Avatar } from "@mui/material";
import React, { useMemo } from "react";
import theme from "../../../theme";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

type Props = {
  income: number;
};

export const IncomeCard = ({ income }: Props) => {
  const formatter = useMemo(() => new Intl.NumberFormat("en-US"), []);

  return (
    <Paper
      sx={{
        minHeight: 250,
        p: theme.spacing(2),
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "space-between",
      }}
      variant="outlined"
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{ mr: 1, background: theme.palette.secondary.main }}
            variant="rounded"
          >
            <AttachMoneyIcon />
          </Avatar>
          Median Income
        </Typography>
        <Chip
          clickable
          variant="outlined"
          size="small"
          component="a"
          label="US Census"
          href="https://www.census.gov/en.html"
          target="_blank"
          color="secondary"
        />
      </Grid>
      <Typography variant="h6" width="100%" align="center">
        {`$${formatter.format(income)}`}
      </Typography>

      <Typography
        variant="overline"
        sx={{ display: "flex", alignContent: "center" }}
      >
        Data from US Census - ACS 5-year Survey
      </Typography>
    </Paper>
  );
};
