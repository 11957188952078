import React from "react";
import Typography from "@mui/material/Typography";
import { ZipAutocomplete } from "./components/zip-autocomplete";

export default function ProTip() {
  return (
    <>
      <Typography sx={{ mt: 2, mb: 3 }} color="text.secondary" align="center">
        Find what your home rents for today - 100% free
      </Typography>
      <ZipAutocomplete />
    </>
  );
}
