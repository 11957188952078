import { Avatar, Button, Grid, Link, Typography } from "@mui/material";
import React from "react";
import theme from "../theme";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: theme.palette.action.disabledBackground,
        marginTop: "auto",
        height: {
          xs: 200,
          sm: 60,
        },
        px: 4,
      }}
    >
      <Grid
        container
        item
        xs={12}
        sm={2}
        justifyContent={{ xs: "center", sm: "flex-start" }}
      >
        <Button
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
          startIcon={
            <Avatar
              alt="Logo"
              src="/assets/properana.png"
              sx={{ height: 48, width: 48 }}
            />
          }
          sx={{ color: theme.palette.text.secondary }}
        >
          Properana
        </Button>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://properana.com/">
            Properana
          </Link>{" "}
          {new Date().getFullYear()}.
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={2}
        justifyContent={{ xs: "center", sm: "flex-end" }}
      >
        <Button
          sx={{ color: theme.palette.text.secondary }}
          href="/privacy"
          onClick={(e) => {
            e.preventDefault();
            navigate("/privacy");
          }}
        >
          Privacy
        </Button>
        <Button
          sx={{ color: theme.palette.text.secondary }}
          href="/terms"
          onClick={(e) => {
            e.preventDefault();
            navigate("/terms");
          }}
        >
          Terms
        </Button>
      </Grid>
    </Grid>
  );
};
