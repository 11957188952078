import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRentsForZip, Rents } from "../../api";
import { RentsTable } from "../../RentsTable";
import { ZipAutocomplete } from "../../components/zip-autocomplete";
import { Offset } from "../../components/offset";
import { PPSQFTChart } from "../../components/ppsqft-chart";
import { PopulationCard } from "./components/population-card";
import { RentCard } from "./components/rent-card";
import { IncomeCard } from "./components/income-card";
import { MapsCard } from "./components/maps-card";
import { Helmet } from "react-helmet";

type Rent = {
  beds: number;
  rent: Rents;
};

export const Analysis = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [rents, setRents] = useState<Rent[]>([]);
  const [image, setImage] = useState<string>("");

  const [baths, setBaths] = useState<number>(0);

  const { zipCode } = useParams();

  useEffect(() => {
    async function getRents(zipCode: string) {
      setLoading(true);

      const requests = Array(3)
        .fill(0)
        .map((_, idx) => getRentsForZip(zipCode, idx + 2));

      const rents = await Promise.all(requests);

      setRents(
        rents.map((r, idx) => {
          return { beds: idx + 2, rent: r };
        })
      );
      setImage(rents[0].images[0].contentUrl);

      setLoading(false);
    }

    if (zipCode) {
      getRents(zipCode);
    }
  }, [zipCode]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "100%",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <Typography variant="overline">Generating analysis...</Typography>
      </div>
    );
  }

  if (rents.length === 0) {
    return <>error</>;
  }

  const { population, growth, income, lat, lng, geometry } =
    rents[0].rent.metadata;
  const { displayName } = rents[0].rent.filter;

  const propertyCount = rents
    .map((r) => r.rent.rents.properties)
    .reduce((acc, cur) => acc + cur);

  return (
    <>
      <Helmet>
        <title>Properana - {zipCode}</title>
        <meta
          name="description"
          content={`Use Properana to find what your home in ${zipCode} should rent for.`}
        />
      </Helmet>
      <Offset />
      <Box sx={{ padding: 4, display: "flex", justifyContent: "center" }}>
        <Grid container maxWidth="lg" spacing={4}>
          <Grid item xs={12}>
            {/* Search bar */}
            <ZipAutocomplete inputValue={zipCode} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{displayName}</Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <RegionCard
              population={population}
              income={income}
              growth={growth}
            />
          </Grid> */}
          <Grid item xs={12} md={8}>
            <MapsCard
              geometry={geometry.geometry}
              center={{ lat: Number(lat), lng: Number(lng) }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container flexDirection="column" spacing={2}>
              <Grid item xs={12}>
                <PopulationCard population={population} growth={growth} />
              </Grid>
              <Grid item xs={12}>
                <IncomeCard income={income} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Rents at a glance</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Data from {propertyCount} properties
            </Typography>
          </Grid>
          {rents.map((rent) => (
            <Grid item xs={12} md={4}>
              <RentCard beds={rent.beds} rents={rent.rent} />
            </Grid>
          ))}
          <Grid container item xs={12}>
            <Grid container item xs={4} alignContent="center">
              <Typography variant="h5">Details</Typography>
            </Grid>
            <Grid
              container
              item
              xs={8}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Select
                disabled
                displayEmpty
                value={baths}
                onChange={(event) => setBaths(event.target.value as number)}
                size="small"
              >
                <MenuItem value={0}>Baths: any</MenuItem>
                <MenuItem value={1}>Baths: 1</MenuItem>
                <MenuItem value={2}>Baths: 2</MenuItem>
                <MenuItem value={3}>Baths: 3</MenuItem>
                <MenuItem value={4}>Baths: 4</MenuItem>
              </Select>
              <Button onClick={() => setBaths(0)}>Clear</Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={12} md={4}>
              <PPSQFTChart
                sqft={rents.map((r) => r.rent.rents.avgsqft)}
                prices={rents.map((r) => r.rent.rents.ppsqft)}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <RentsTable
                rents={rents.map((r) => r.rent)}
                propertyCount={propertyCount}
              />
              <Button>Zillow</Button>
              <Button>Redfin</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Offset />
    </>
  );
};
