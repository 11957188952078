import React, { useEffect, useMemo, useState } from "react";
import { verifyToken } from "../../api/auth";

export const ID_TOKEN_KEY = "idToken";

const redirectUri = process.env.REACT_APP_AUTH_REDIRECT
  ? `${process.env.REACT_APP_AUTH_REDIRECT}/signin`
  : "http://localhost:3000/signin";

export const AuthContext = React.createContext({
  isAuthenticated: false,
  signIn: (idToken: string) => {},
  signOut: () => {},
});

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [idToken, setIdToken] = useState<string | null>(null);

  useEffect(() => {
    const idToken = localStorage.getItem(ID_TOKEN_KEY);

    if (idToken) {
      verifyToken(idToken)
        .then(() => {
          setIdToken(idToken);
          setIsAuthenticated(true);
        })
        .catch(() => {
          localStorage.removeItem(ID_TOKEN_KEY);
          setIdToken(null);
          setIsAuthenticated(false);
        });
    }
  }, [idToken]);

  const signIn = (idToken: string) => {
    localStorage.setItem(ID_TOKEN_KEY, idToken);
    setIdToken(idToken);
  };

  const signOut = () => {
    localStorage.removeItem(ID_TOKEN_KEY);
    window.location.replace(
      `https://properana.auth.us-east-2.amazoncognito.com/logout?client_id=5f1k1abm4r5pnofenno5osl49n&logout_uri=${redirectUri}`
    );
  };

  const value = useMemo(
    () => ({ isAuthenticated, idToken, signIn, signOut }),
    [isAuthenticated, idToken, signIn, signOut]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const injectAuthHeaders = (headers: Record<any, any>) => {
  const idToken = localStorage.getItem(ID_TOKEN_KEY);

  return {
    ...headers,

    // Inject authorization headers if key is in local storage.
    ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
  };
};
