import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FilterListIcon from "@mui/icons-material/FilterList";
import AssessmentIcon from "@mui/icons-material/Assessment";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

import React from "react";
import { Helmet } from "react-helmet";
import { Offset } from "../../components/offset";
import theme from "../../theme";
import { Feature, FeatureList } from "./components";

const free: Feature[] = [
  {
    icon: <SearchIcon />,
    primary: "Unlimited searches",
    secondary: "Search all you want, free forever!",
  },
];
const professional: Feature[] = [
  ...free,
  {
    icon: <DashboardIcon />,
    primary: "Dashboard",
    secondary: "Save and watch regions, view all of your markets in one place",
  },
  {
    icon: <FilterListIcon />,
    primary: "Search filters",
    secondary: "Narrow down your search and get detailed analysis",
  },
];
const enterprise: Feature[] = [
  ...professional,
  {
    icon: <AssessmentIcon />,
    primary: "Generate reports",
    secondary: "Generate and send reports to your clients or landlord",
  },
  {
    icon: <IntegrationInstructionsIcon />,
    primary: "3rd party integrations with other SaaS solutions",
    secondary:
      "We provide a variety of integrations with common SaaS platforms like Slack, Dropbox, Google Drive, and more",
  },
];

export const Pricing = () => {
  return (
    <>
      <Offset />
      <Helmet>
        <title>Properana - Pricing</title>
      </Helmet>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Grid container item xs={3} justifyContent="center">
          <Card sx={{ width: "100%" }}>
            <Avatar
              sx={{
                height: 64,
                width: 64,
                margin: "auto",
                marginTop: theme.spacing(2),
                background: theme.palette.secondary.light,
              }}
            >
              <SentimentSatisfiedIcon sx={{ height: 48, width: 48 }} />
            </Avatar>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                align="center"
              >
                Free
              </Typography>
              <FeatureList features={free} />
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={3} justifyContent="center">
          <Card sx={{ width: "100%" }}>
            <Avatar
              sx={{
                height: 64,
                width: 64,
                margin: "auto",
                marginTop: theme.spacing(2),
                background: theme.palette.secondary.light,
              }}
            >
              <SentimentSatisfiedAltIcon sx={{ height: 48, width: 48 }} />
            </Avatar>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                align="center"
              >
                Professional
              </Typography>
              <FeatureList features={professional} />
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={3} justifyContent="center">
          <Card sx={{ width: "100%" }}>
            <Avatar
              sx={{
                height: 64,
                width: 64,
                margin: "auto",
                marginTop: theme.spacing(2),
                background: theme.palette.secondary.light,
              }}
            >
              <SentimentVerySatisfiedIcon sx={{ height: 48, width: 48 }} />
            </Avatar>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                align="center"
              >
                Enterprise
              </Typography>
              <FeatureList features={enterprise} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
