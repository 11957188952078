import React, { FormEvent, useContext, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../theme";
import { signIn } from "../api/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { AuthContext, AuthProvider } from "../hooks/auth/auth";
import { Helmet } from "react-helmet";

const redirectUri = process.env.REACT_APP_AUTH_REDIRECT
  ? `${process.env.REACT_APP_AUTH_REDIRECT}/auth`
  : "http://localhost:3000/auth";

export default function SignIn() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { signIn: storeToken } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const { idToken } = await signIn(username, password);

      storeToken(idToken);
      navigate("/");
      enqueueSnackbar("Success! Welcome back", {
        variant: "success",
      });
    } catch (e: any) {
      enqueueSnackbar(e.toString());
    }
  };

  return (
    <>
      <Helmet>
        <title>Properana - Sign in</title>
        <meta
          name="description"
          content={`Sign in to access all of Properana's features.`}
        />
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Card
          sx={{
            minHeight: 600,
            width: 500,
            display: "flex",
            alignItems: "center",
          }}
          variant="outlined"
        >
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
              >
                <Avatar
                  src="/assets/properana.png"
                  sx={{ width: 128, height: 128 }}
                />
                <Typography variant="h5" color="primary" align="center">
                  Properana
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  Sign in to continue
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  startIcon={
                    <Avatar
                      alt="Google Logo"
                      sx={{ width: 24, height: 24 }}
                      src="assets/g-logo.png"
                    />
                  }
                  variant="outlined"
                  sx={{ color: theme.palette.text.secondary }}
                  href={`https://properana.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=TOKEN&client_id=5f1k1abm4r5pnofenno5osl49n&scope=email openid profile`}
                >
                  Sign in with Google
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 2, mb: 4 }} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              component="form"
              justifyContent="center"
              onSubmit={onSubmit}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={username}
                  placeholder="Email"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid container item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={password}
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Link href="#" underline="none" sx={{ ml: "auto", mt: 1 }}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Button variant="contained" type="submit" fullWidth>
                  Sign in
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 2 }} />
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Link
                  href="/signup"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/signup");
                  }}
                >
                  Don't have an account? Sign up
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

// https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=665288597089-ghaenc6q1l7ih6og5hcb573q9p0s93kh.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fproperana.auth.us-east-2.amazoncognito.com%2Foauth2%2Fidpresponse&scope=profile%20email%20openid&response_type=code&state=H4sIAAAAAAAAAG1SXW_iMBD8L3k-KAEMhDe-ApRSEAGKVJ2QcTbEjeM1tmkop_vv59BS3UMfd3bGszvrP97ZgF4iimnsdV1RAWpspb6_FLmZt-2Ve788pfGdx6CfaQ6ONEY8CnA4ExykvQlJ4mc-PeRNTZTEBKREgkY0A-l4GmKugdnNauqoqbXKdB8eiqKowoXmSkCVYX7jGYXSwPpDlT4WM5D_2X_B3_aGoQLjdV89yCkXDnG15PGnJOGO9NuxLLVOJs9CuIkxhkFKhQB5_Bmcg00xvrckSlZ6Xuvj9amQu0A01XQ_Fo_ET0mPhYd-VkSHaWPeMWwEa1Vsd2-bdqezMYPJW78_C80KwkxuJlceNfbzS3r2s5fWdE6D2X5gwvHiabR9HquidjFZStnLR0QWl10xTEZ7Sla1QaPTW2Buli1ySpbb9mxZa7PTMAjqw4zXRuvkOhxW6LEVPIZyEk3SyubQK4MB_Q56gsYuUVs3votDgaaSVunZptXvK1dpTq9uRTxKbvHrCkwDtRzlmucQAUMZu4z9FvFrbUI69fJ5Y1z_nlH5gXrWan442_Icn-gt9hD1E5cZl8forkmoMPD3HwU8Hyx3AgAA.H4sIAAAAAAAAAHMW2NnRFRz5K-lx9v2ZqktXpP3-8-iSWni81K_Y1icXeUoB9x59OyAAAAA.3&flowName=GeneralOAuthFlow
