import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { Offset } from "../../components/offset";
import theme from "../../theme";

export const About = () => {
  return (
    <>
      <Offset />
      <Helmet>
        <title>Properana - About</title>
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", marginTop: theme.spacing(4) }}
      >
        <Grid
          container
          item
          xs={4}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="overline"
            align="center"
            color="primary"
            sx={{ fontSize: 24 }}
          >
            About us
          </Typography>
          <Typography variant="h4" align="center" gutterBottom>
            We're on a mission to empower both landlords and renters by making
            rent data accessible.
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom>
            Our data is collected and aggregated from a wide range of sources to
            provide an accurate and unbiased lense into your region.
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignContent="center"
        >
          <Grid container item xs={3} justifyContent="center">
            <img src="/assets/uscensus.png" height="50px" />
          </Grid>
          <Grid container item xs={3} justifyContent="center">
            <img src="/assets/zillow.png" height="40px" />
          </Grid>
          <Grid container item xs={3} justifyContent="center">
            <img src="/assets/redfin.png" height="50px" />
          </Grid>
          <Grid container item xs={3} justifyContent="center">
            <img src="/assets/trulia.png" height="50px" />
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: theme.palette.divider,
          padding: theme.spacing(8),
          marginTop: theme.spacing(8),
        }}
      >
        <Grid container maxWidth="md">
          <Typography variant="h3" width="100%" gutterBottom>
            Leadership team
          </Typography>
          <Typography variant="subtitle1">
            Properana is developed by people passionate about real estate and
            housing. Our goal is to support both home owners and renters to make
            informed decisions.
          </Typography>
          <Divider sx={{ borderColor: "white", mt: 2, mb: 2, width: "100%" }} />
          <Card variant="outlined" sx={{ height: 250, width: 250 }}>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography variant="h6">Ben Krig</Typography>
                  <Typography variant="body2">Founder</Typography>
                </Grid>
                <Avatar
                  variant="rounded"
                  src="/assets/ben.jpeg"
                  sx={{ height: 64, width: 64, marginBottom: theme.spacing(1) }}
                />
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography variant="body2" gutterBottom>
                Ben founded Properana in 2022 with a goal of providing an open
                look into the real estate market. He brings a focus onto equal
                and open access to real estate data as a first principle.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </>
  );
};
